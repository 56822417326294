<!-- 批量处理学员相片 -->
<template>
  <div class="batchProcessing">
    <div class="top">
      <div class="" style="margin-left: 20px; display: flex">
        <el-button
          size="small"
          icon="el-icon-refresh"
          style="background-color: #2c3e50; border-radius: 3px"
          @click="refresh"
        >
          <!-- 刷新 -->
        </el-button>
        <!-- 导入 -->
        <el-upload
          ref="upload"
          action="https://artxt.szart.cn/api/public/index.php/api/common/upload2"
          :limit="1"
          :before-upload="beforeUpload"
          accept=".rar,.zip"
          class="up-class"
          :on-success="uploadSuccess"
          :show-file-list="false"
          :auto-upload="true"
        >
          <el-button slot="trigger" size="small" class="daoru">
            批量导入
          </el-button>
        </el-upload>
        <el-button
          slot="trigger"
          size="small"
          class="leadingOut"
          @click="leadingOut"
          >批量导出</el-button
        >
        <!-- <template>
                    <div class="" style="margin-top: 5px;">
                        <span style="margin: 5px 10px;">全部</span>
                        <el-checkbox v-model="checked"></el-checkbox>
                    </div>
                </template> -->
      </div>
      <!-- <div class="" style="margin-right: 20px;">
                <el-button type="danger" icon="el-icon-delete" size="small">
                    删除
                </el-button>
            </div> -->
      <!-- 弹出提示 -->
      <el-dialog
        title="提示"
        :visible.sync="dialog"
        width="40%"
        :before-close="handleClose"
      >
        <span>正在导入相片中，请稍等。。。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog = false">取 消</el-button>
          <el-button
            style="background: #409eff; color: #fff"
            @click="dialog = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="t-box">
      <!-- 左边 -->
      <div class="t-left">
        <dl v-for="(item, index) in list" :key="index" @click="details(item)">
          <dt><img :src="item.head_image" /></dt>
          <dd>姓名：<input type="text" v-model="item.username" disabled /></dd>
          <dd>身份证：<input type="text" v-model="item.idcard" disabled /></dd>
        </dl>
        <div class="" style="text-align: center; clear: both">
          <!--分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10]"
            :page-size="10"
            background
            :pager-count="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 右边 -->
      <div class="t-right">
        <div class="" style="display: flex; justify-content: space-between">
          <!-- 左表单 -->
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            class="form"
            label-width="100px"
          >
            <el-form-item label="学员编号">
              <el-input
                v-model="form.number"
                placeholder="请输入学员编号"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="拼音名">
              <el-input
                v-model="form.pinyin"
                placeholder="请输入拼音名"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="移动电话">
              <el-input
                v-model="form.mobilePhone"
                placeholder="请输入移动电话"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="入学年份">
              <el-date-picker
                v-model="form.year"
                value-format="yyyy-MM-dd"
                disabled
                type="date"
                placeholder="请选择入学年份"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="证件号码" prop="idNum">
              <el-input
                v-model="form.idNum"
                placeholder="请输入证件号码"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="现年级">
              <el-input
                v-model="form.currentGrade"
                placeholder="请输入现年级"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="民族">
              <el-input
                v-model="form.nation"
                placeholder="请输入民族"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="国籍">
              <el-input
                v-model="form.nationality"
                placeholder="请输入国籍"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="家长姓名">
              <el-input
                v-model="form.parentName"
                placeholder="请输入家长姓名"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="家长密码">
              <el-input
                v-model="form.parentPwd"
                placeholder="请输入家长密码"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="家庭电话">
              <el-input
                v-model="form.homePhone"
                placeholder="请输入家庭电话"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="家庭住址">
              <el-input
                v-model="form.address"
                placeholder="请输入家庭住址"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="账号有效期">
              <el-date-picker
                v-model="form.effective"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择账号有效期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="邮政编码">
              <el-input
                v-model="form.postalCode"
                placeholder="请输入邮政编码"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- 右表单 -->
          <el-form ref="form" :model="form" class="form" label-width="100px">
            <el-form-item label="学员姓名">
              <el-input
                v-model="form.name"
                placeholder="请输入学员姓名"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="学员卡号">
              <el-input
                v-model="form.cardNumber"
                placeholder="请输入学员卡号"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="助记码">
              <el-input
                v-model="form.mnemonicCode"
                placeholder="请输入助记码"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="就读学校">
              <el-input
                v-model="form.school"
                placeholder="请输入就读学校"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="备用证件号码">
              <el-input
                v-model="form.spareId"
                placeholder="请输入备用证件号码"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="出生日期">
              <el-date-picker
                v-model="form.birthday"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择出生日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="性别">
              <div id="sex" style="width: 300px">
                <el-radio-group v-model="form.sex" disabled>
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="年龄">
              <div id="sex" style="width: 300px">
                <el-radio-group v-model="form.age" disabled>
                  <el-radio :label="1">少儿</el-radio>
                  <el-radio :label="2">成人</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="选择学校">
              <div id="sex" style="width: 300px">
                <el-radio-group v-model="form.choiceSchool" disabled>
                  <el-radio :label="1">未入学</el-radio>
                  <el-radio :label="2">试学</el-radio>
                  <el-radio :label="3">学员</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="选择机构">
              <div id="sex" style="width: 300px">
                <el-radio-group v-model="form.mechanism" disabled>
                  <el-radio :label="1">培训</el-radio>
                  <el-radio :label="2">考试</el-radio>
                  <el-radio :label="3">两者</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="">
              <span>照片</span>
              <el-upload
                class="avatar-uploader"
                action="https://artxt.szart.cn/api/public/index.php/api/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="this.fullurl !== ''"
                  :src="this.fullurl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- <div class="" style="width: 150px;height: 210px;border: 1px solid #ccc;">
                                <img :src="picture" style="width: 100%;height: 100%;">
                            </div> -->
            </el-form-item>
          </el-form>
        </div>
        <!-- 底部 -->
        <div slot="footer" class="dialog-footer">
          <el-button
            style="background-color: #18bc9c; color: #ffffff"
            @click="submitForm('form')"
            >保 存
          </el-button>
          <!-- <el-button @click="resetForm('form')">取 消</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //照片列表
      checked: false,
      currentPage: 1,
      currentLimit: 10, //条数
      total: 0,
      form: {
        number: "", //学员编号
        pinyin: "", //拼音名
        mobilePhone: "", //移动电话
        year: "", //入学年份
        idNum: "", //证件号码
        currentGrade: "", //现年级
        nation: "", //名族
        nationality: "", //国籍
        parentName: "", //家长姓名
        parentPwd: "", //家长密码
        homePhone: "", //家庭电话
        address: "", //家庭住址
        effective: "", //账号有效期
        postalCode: "", //邮政编码
        name: "", //学员姓名
        cardNumber: "", //学员卡号
        mnemonicCode: "", //助记码
        school: "", //就读学校
        spareId: "", //备用证件号码
        birthday: "", //出生日期
        sex: 1, //性别
        age: 1, //年龄
        choiceSchool: 1, //选择学校
        mechanism: 1, //选择机构
        picture: "", //照片
      },
      rules: {
        idNum: [
          {
            required: true,
            message: "证件号码不能为空",
            trigger: "blur",
          },
        ],
      },
      imageUrl: null, //照片上传
      fullurl: "", //显示图片
      url: "", //传的图片
      dialog: false, //用于判断弹窗
      bool: false,
      id: "", //接收id
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //批量导出照片
    leadingOut() {
      this.$request({
        url: "/api/student/album_list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
          export: 1,
        },
      }).then((res) => {
        console.log(res, "批量导出照片");
        let a = document.createElement("a");
        a.href = res.data;
        a.click();
      });
    },
    // 获取列表
    getList() {
      this.$request({
        url: "/api/student/album_list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
        },
      }).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.list = res.data.list;
          this.total = res.data.count;
        }
      });
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          if (this.bool) {
            return;
          }
          this.bool = true;
          setTimeout(() => {
            this.bool = false;
          }, 1500);
          this.$request({
            url: "/api/student/edit",
            method: "POST",
            data: {
              id: this.id,
              head_image: this.url,
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.getList();
                // this.$router.go(0);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(form) {
      this.$refs[form].resetFields();
    },
    // 导入
    beforeUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(extension);
      if (extension !== "zip") {
        this.$message({
          message: "上传文件只能是zip格式!",
          type: "warning",
        });
        return false;
      }
      this.dialog = true;
    },
    uploadSuccess(res, file, fileList) {
      this.dialog = false;
      if (res.code == 1) {
        this.$message({
          message: "导入成功!",
          type: "success",
        });
      } else {
        this.$alert(res.msg, "导入失败", {
          confirmButtonText: "确定",
        });
      }
      this.$refs.upload.clearFiles();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = res.data;
      this.fullurl = this.imageUrl.fullurl;
      this.url = this.imageUrl.url;
    },
    beforeAvatarUpload(file) {
      console.log(file.type);
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      let flag = [isJPG, isJPEG].includes(true);
      if (!flag) {
        this.$message.error("上传头像图片只能是 JPG/JPEG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }

      return flag && isLt1M;
    },

    // 获取id渲染详情
    details(item) {
      // this.id =
      this.$request({
        url: "/api/student/detail",
        method: "POST",
        data: {
          id: item.id,
        },
      }).then((res) => {
        if (res.code == 1) {
          console.log(res);
          let data = res.data.data;
          let form = this.form;
          form.number = data.number; //学员编号
          form.pinyin = data.pinyin_name; //拼音名
          form.mobilePhone = data.mobile1; //移动电话
          form.year = data.start_school; //入学年份
          form.idNum = data.idcard; //证件号码
          form.currentGrade = data.grade; //现年级
          form.nation = data.nation; //民族
          form.nationality = data.nation2; //国籍
          form.parentName = data.parent_name; //家长姓名
          form.parentPwd = data.parent_pwd; //家长密码
          form.homePhone = data.mobile2; //家庭电话
          form.address = data.address; //家庭住址
          form.effective = data.effective_time; //账号有效期
          form.postalCode = data.post_code; //邮政编码
          form.name = data.username; //学员姓名
          form.cardNumber = data.student_card; //学员卡号
          form.mnemonicCode = data.mcode; //助记码
          form.school = data.school_name; //就读学校
          form.spareId = data.idcard2; //备用证件号码
          form.birthday = data.birthdate; //出生日期
          form.sex = data.gender; //性别
          form.age = data.grade; //年龄
          form.choiceSchool = data.school_type; //选择学校
          form.mechanism = data.mechanism_type; //选择机构
          this.fullurl = data.head_image; //照片
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
.batchProcessing {
  width: 100%;
  height: 100%;
}

.top {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

/* 批量导入按钮 */
.daoru {
  background: url(../../assets/import.png) 8px 7px no-repeat #ff7300;
  color: #ffffff;
  height: 31px;
  width: 90px;
  padding-left: 25px;
  border: none;
  vertical-align: middle;
  margin: 0px 10px;
  cursor: pointer;
}

/* 导出按钮 */
.leadingOut {
  width: 95px;
  height: 30px;
  vertical-align: middle;
  background: url(../../assets/export.png) 8px 7px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.batchProcessing ::v-deep .el-dialog__footer {
  padding: 10px 20px 20px !important;
}
.batchProcessing ::v-deep .el-dialog__body {
  padding: 25px 25px 30px !important;
}

.el-button--primary {
  background: url(../../assets/import.png) 8px 7px no-repeat #ff7300;
}

/* 大盒子 */
.t-box {
  width: 100%;
  height: 805px;
  display: flex;
  justify-content: space-around;
}

/* 左边 */
.t-left {
  padding-top: 10px;
  /* border: 1px solid #000; */
  width: 700px;
  height: 790px;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

dl {
  width: 339px;
  height: 120px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  cursor: pointer;
}

dt {
  width: 87px;
  height: 100px;
  margin-left: 10px;
  margin-top: 10px;
  float: left;
}

dt img {
  width: 100%;
  height: 100%;
}

dd {
  margin-top: 25px;
  text-align: right;
  font-size: 14px;
  float: right;
}

input {
  text-indent: 5px;
  font-size: 15px;
  border: none;
  outline: none;
  width: 165px;
  margin-right: 12px;
  border-bottom: 1px solid #ccc;
  color: #333333;
  cursor: default;
  background-color: #fff;
}

/* 右边 */
.t-right {
  width: 700px;
  height: 810px;
  /* border: 1px solid #000; */
  background-color: #f7f7f7;
  margin-right: 10px;
}

/* 表单 */
.form {
  width: 100%;
  margin-top: 15px;
}

.form ::v-deep.el-form-item {
  margin-bottom: 10px !important;
}

.form ::v-deep.el-form-item__error {
  padding-top: 0px !important;
}
.form ::v-deep .el-input__inner {
  width: 240px;
  height: 32px !important;
}

.el-input {
  width: 240px;
  height: 32px !important;
}

.el-select {
  width: 240px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}
.form ::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #606266;
}
/* 性别选择按钮 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}

/* 分页 */
.el-pagination {
  margin: 0px auto;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 15px;
}

.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}

/* 照片 */
.form ::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 130px !important;
  height: 150px !important;
}

.form .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.form .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.form .avatar {
  width: 130px;
  height: 150px;
  /* display: block; */
}
</style>
